.alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.alert-box {
    background-color: white;
    border-radius: 10px;
    width: 300px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.alert-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}


.alert-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
}

.alert-button:hover {
    background-color: #0056b3;
}


.alert-message {
    font-size: 14px;
    margin-bottom: 20px;
}

.loading-spinner {

    border: 4px solid #f3f3f3;
    /* Light gray */
    border-top: 4px solid #007bff;
    /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
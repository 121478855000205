/* .pet-profile { */
/* width: 100%; */
/* border-radius: 15px; */
/* overflow: hidden; */
/* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
/* font-family: Arial, sans-serif; */
/* } */
@media (max-width: 576px) {
  .pet-profile {
    position: relative;
    /* border-radius: 15px; */
    /* overflow: hidden; */
    font-family: Arial, sans-serif;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .image-section {
    position: relative;
    width: 100%;
    height: auto; /* Will adjust according to the image */
  }

  .dog-image {
    width: 100%;
    height: auto;
  }
  .pet-profile-header{
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    align-items: center;
  }

  .profile-name-section {
    /* position: relative;
    top: 350px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 98%;
    height: 100px;
    border-radius: 20px;
    margin: 1px;
    padding: 5px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: black;
    font-family: Arial, sans-serif; */
    position: relative;  /* relative positioning will let flexbox handle it */
    width: 95%;
    top: -50px;
    min-height: 100px;
    border-radius: 20px;
    padding: 5px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: black;
    font-family: Arial, sans-serif;
  }

  .name-section h2 {
    font-size: 2rem;
    color: black;
    margin: 10px;
  }

  .name-section p {
    font-size: 1rem;
    color: black;
    margin: 10px;
  }

  .profile-detail {
    /* background-color: red; */
  }

  .about-section {
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
  }

  .about-section h3 {
    font-size: 1.5rem;
    color: black;
    font-weight: bold;
  }

  .stats {
    display: flex;
    justify-content: space-around;
    margin: 10px 0;
    width: 100%;
  }

  .stat {
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.3);
    /* Light border for emphasis */
    padding: 10px;
    border-radius: 10px;
    min-width: 80px;
    background-color: aliceblue;
  }

  .stat p {
    font-size: 0.8rem;
    color: #666;
    margin: 0;
  }

  .stat h4 {
    font-size: 1rem;
    margin: 5px 0;
    color: #427fc1;
    font-weight: 600;
  }

  .location-section {
    margin-top: 10px;
    padding: 10px;
  }

  .location-section button {
    width: 100%;
  }

  .behaviour-section {
    margin-top: 10px;
    padding: 10px;
  }

  .behaviour-section h3 {
    font-size: 1.5rem;
    color: black;
    font-weight: bold;
  }

  .health-section {
    margin-top: 10px;
    padding: 10px;
  }

  .health-section h3 {
    font-size: 1.5rem;
    color: black;
    font-weight: bold;
  }

  .food-section {
    margin-top: 10px;
    padding: 10px;
  }

  .food-section h3 {
    font-size: 1.5rem;
    color: black;
    font-weight: bold;
  }

  .capsule-area {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: flex-start;
    box-sizing: border-box;
  }


  .capsule {
    display: inline-block;
    padding: 5px 15px;
    /* Padding for the tags */
    background-color: #fff;
    /* Background color for tags */
    border: 1px solid #427fc1;
    /* Light blue border */
    border-radius: 20px;
    /* Makes the tags pill-shaped */
    font-size: 0.9rem;
    /* Font size for the text */
    color: #333;
    /* Dark text color */
    font-weight: 500;
    /* Slightly bold text */
    white-space: nowrap;
    /* Prevents tags from breaking onto multiple lines */
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for a clean look */
  }


  .family-section {
    margin-top: 10px;
    padding: 10px;
  }

  .family-section h3 {
    font-size: 1.5rem;
    color: black;
    font-weight: bold;
  }

  .family-area {}

  .family-member {
    /* background-color: aqua; */
  }

  .family-member .header {
    /* background-color: #427fc1; */
    display: flex;
    /* padding: 10px; */
    align-items: center;

    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }

    .detail {
      margin-left: 10px;
      width: 45%;

      .contact-info p {
        font-size: 0.8rem;
        color: black;
        font-weight: normal;
      }
    }

    .detail h4 {
      padding-top: 10px;
      font-size: 1.2rem;
      font-weight: bold;

      span {
        font-size: 1rem;
        font-weight: normal;
      }
    }

  }

  .contact-info p {
    font-size: 1rem;
    color: black;
    font-weight: bold;
  }

  .contact-icon-area {
    min-width: 40%;
    display: flex;
    justify-content: space-around;
  }

  .contact-icon {
    width: 40px;
    height: 40px;
  }

  .location-button {
    width: 100%;
    padding: 10px;
    background-color: #427fc1;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }


  .location {}

  .location .first-section {
    text-align: center;
    padding: 10px 5px;
    background-color: #427fc1;

    h2 {
      color: white;
      font-size: 1.5rem;
    }
  }

  .location .second-section {
    .location-detail {
      margin: 10px 0px;
      padding: 0px 2x;

      text-align: center;

      p {
        font-size: 0.8;
        font-weight: normal;
        color: black;
      }
    }

    .address {
      width: 50%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h3 {
        font-size: 1.2rem;
        font-weight: bold;
      }

      p {
        font-size: 1rem;
        font-weight: normal;
      }
    }
  }

  .no-location {
    text-align: center;
    padding: 20px;

    p {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  .history-section {
    div {
      display: flex;
      justify-content: flex-end;
      button {
        margin: 5px;
        padding: 5px 10px;
        background-color: #427fc1;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }
    }

  }
}



@media (min-width: 577px) {
  .pet-profile {
    padding: 0px 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    position: relative;
    /* border-radius: 15px; */
    /* overflow: hidden; */
    font-family: Arial, sans-serif;
    box-shadow: 0px 4px 6px rgba(184, 181, 181, 0.1);
  }

  .image-section {
    position: relative;
    width: auto;
    height: auto;
  }

  .dog-image {
    width: 100%;
    height: 400px;
    border-radius: 20px;
  }
  .pet-profile-header{
    width: 30%;
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    align-items: center;
  }

  .profile-name-section {
    position: relative;  /* relative positioning will let flexbox handle it */
    width: 95%;
    top: -50px;
    min-height: 100px;
    border-radius: 20px;
    padding: 5px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: black;
    font-family: Arial, sans-serif;
    /* position: absolute;
    top: 350px;
    left: 28px;
    min-width: 29%;
    height: 100px;
    border-radius: 20px;
    margin: 1px;
    padding: 5px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: black;
    font-family: Arial, sans-serif; */
  }

  .name-section h2 {
    font-size: 2rem;
    color: black;
    margin: 10px;
  }

  .name-section p {
    font-size: 1rem;
    color: black;
    margin: 10px;
  }

  .profile-detail {
    /* background-color: red; */
  }

  .section-2-area{
    display: flex;
    flex-direction: column;
    width: 33%;
  }

  .about-section {
    /* margin-top: 40px; */
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
  }

  .about-section h3 {
    font-size: 1.5rem;
    color: black;
    font-weight: bold;
  }

  .stats {
    display: flex;
    justify-content: space-around;
    margin: 10px 0;
    width: 100%;
  }

  .stat {
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.3);
    /* Light border for emphasis */
    padding: 10px;
    border-radius: 10px;
    min-width: 80px;
    background-color: aliceblue;
  }

  .stat p {
    font-size: 0.8rem;
    color: #666;
    margin: 0;
  }

  .stat h4 {
    font-size: 1rem;
    margin: 5px 0;
    color: #427fc1;
    font-weight: 600;
  }

  .location-section {
    margin-top: 10px;
    padding: 10px;
  }

  .location-section button {
    width: 100%;
  }

  .behaviour-section {
    margin-top: 10px;
    padding: 10px;
  }

  .behaviour-section h3 {
    font-size: 1.5rem;
    color: black;
    font-weight: bold;
  }

  .health-section {
    margin-top: 10px;
    padding: 10px;
  }

  .health-section h3 {
    font-size: 1.5rem;
    color: black;
    font-weight: bold;
  }

  .food-section {
    margin-top: 10px;
    padding: 10px;
  }

  .food-section h3 {
    font-size: 1.5rem;
    color: black;
    font-weight: bold;
  }

  .capsule-area {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: flex-start;
    box-sizing: border-box;
  }


  .capsule {
    display: inline-block;
    padding: 5px 15px;
    /* Padding for the tags */
    background-color: #fff;
    /* Background color for tags */
    border: 1px solid #427fc1;
    /* Light blue border */
    border-radius: 20px;
    /* Makes the tags pill-shaped */
    font-size: 0.9rem;
    /* Font size for the text */
    color: #333;
    /* Dark text color */
    font-weight: 500;
    /* Slightly bold text */
    white-space: nowrap;
    /* Prevents tags from breaking onto multiple lines */
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for a clean look */
  }


  .family-section {
    min-width: 30%;
    /* margin-top: 10px; */
    padding: 10px;
  }

  .family-section h3 {
    font-size: 1.5rem;
    color: black;
    font-weight: bold;
  }

  .family-area {}

  .family-member {
    /* background-color: aqua; */
  }

  .family-member .header {
    /* background-color: #427fc1; */
    display: flex;
    /* padding: 10px; */
    align-items: center;

    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }

    .detail {
      margin-left: 10px;
      width: 45%;

      .contact-info p {
        font-size: 0.8rem;
        color: black;
        font-weight: normal;
      }
    }

    .detail h4 {
      padding-top: 10px;
      font-size: 1.2rem;
      font-weight: bold;

      span {
        font-size: 1rem;
        font-weight: normal;
      }
    }

  }

  .contact-info p {
    font-size: 1rem;
    color: black;
    font-weight: bold;
  }

  .contact-icon-area {
    min-width: 40%;
    display: flex;
    justify-content: space-around;
  }

  .contact-icon {
    width: 40px;
    height: 40px;
  }

  .location-button {
    width: 100%;
    padding: 10px;
    background-color: #427fc1;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }


  .location {}

  .location .first-section {
    text-align: center;
    padding: 10px 5px;
    background-color: #427fc1;

    h2 {
      color: white;
      font-size: 1.5rem;
    }
  }

  .location .second-section {
    .location-detail {
      margin: 10px 0px;
      padding: 0px 2x;

      text-align: center;

      p {
        font-size: 0.8;
        font-weight: normal;
        color: black;
      }
    }

    .address {
      width: 50%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h3 {
        font-size: 1.2rem;
        font-weight: bold;
      }

      p {
        font-size: 1rem;
        font-weight: normal;
      }
    }
  }

  .map {
    width: 100%;
    height: 700px !important;
  }
  
  .no-location {
    text-align: center;
    padding: 20px;

    p {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  .history-section {
    div {
      display: flex;
      justify-content: flex-end;
      button {
        margin: 5px;
        padding: 5px 10px;
        background-color: #427fc1;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }
    }

  }
}


.location-item{
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 20px;
  margin: 5px 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: none;
  background-color: white;
}
.loc-image-map{
  width: 50px;
  height: 50px;
}

      
.history-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


.history-box {
  background-color: white;
  border-radius: 10px;
  width: 300px;
  padding: 20px 0px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.history-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}


.history-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.history-button:hover {
  background-color: #0056b3;
}


.history-message {
  font-size: 14px;
  margin: 5px 0px;
}

.loading-spinner {

  border: 4px solid #f3f3f3;
  /* Light gray */
  border-top: 4px solid #007bff;
  /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
}

.close-button:hover {
  color: #e74c3c;
}



/* Pet Finder Intro */

/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* First Section */
.first-section {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 100vh; /* Full viewport height */
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.first-section .container {
  position: relative;
  z-index: 2;
}

.first-section h4 {
  font-family: 'Poppins', sans-serif;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  /* text-align: center; */
  margin-bottom: 10px;
}

.first-section h2 {
  font-family: 'Inter', sans-serif;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 700;
  /* text-align: center; */
  margin-bottom: 20px;
}

.first-section .row {
  display: flex;
}

.first-section .col {
  display: flex;
  align-items: center;
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .first-section h1 {
    font-size: 36px;
  }

  .first-section h4 {
    font-size: 18px;
  }

  .first-section .btn {
    font-size: 14px;
    padding: 8px 20px;
  }
}



.how-it-works {
  text-align: center;
  padding: 40px 20px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  margin: 40px auto;
}

.title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.text-section {
  flex: 1;
  max-width: 500px;
  text-align: left;
}

.pet-sub-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.pet-sub-title .icon {
  margin-right: 10px;
}

.description {
  font-size: 1rem;
  line-height: 1.6;
  color: #444;
}

.highlight {
  color: #0056b3;
  font-weight: bold;
  text-decoration: underline;
}

.image-section {
  flex: 1;
  max-width: 350px;
}

.image-section img {
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.furtag-advantage {
  background-color: #66b3e6; /* Light blue background */
  color: white;
  text-align: center;
  padding: 40px 20px;
  border-radius: 15px;
  max-width: 1200px;
  margin: 40px auto;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: lowercase;
}

.subtitle {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 30px;
  line-height: 1.6;
}

.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
}

.feature-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px;
  text-align: center;
}

.icon {
  font-size: 2rem;
  margin-bottom: 10px;
}

.feature-item p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
}